<template>
  <div class="container dispatchTasks-warp">
    <div class="common-head">
      <img
        :src="require('@/assets/image/personbg.png')"
        alt=""
        class="bg-img"
      />
      <img class="img-title" :src="require('@/assets/image/pfrw.png')" alt="" />
      <div class="head-bottom-warp">
        <div class="head-item align-left blue">
          <div class="bg-blur"></div>
          <img :src="require('@/assets/image/xq2.png')" class="img-icon" /><span
            >任务处理</span
          >
        </div>
      </div>
    </div>
    <div class="panel-warp">
      <ul class="panel-warp-ul">
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            处理说明<span class="red">*</span>
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <textarea
              v-model="ruleforms.hdl_info"
              placeholder="请输入"
              rows="2"
            ></textarea>
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            <div>
              <div class="t-c">拍照上传</div>
              <div class="f-s10 t-c m-t4">最多支持6张</div>
            </div>
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-uploader
              v-model="fileImgList"
              :after-read="modelImgRead"
              :before-read="handleImagebefread"
              multiple
              max-count="6"
            />
          </div>
        </li>
        <li>
          <div class="panel-item-left bra3 panel-item-block align-flex-top">
            视频上传
          </div>
          <div class="panel-item-right bra3 panel-item-block plr">
            <van-uploader
              v-model="fileVideoList"
              multiple
              max-count="6"
              class="video-upload"
              :before-read="handlebefread"
              :after-read="modelVideoRead"
              @click-preview="handlePreview"
              accept="video/*"
            >
              <div slot="preview-cover">
                <video
                  v-for="(item, index) in fileVideoList"
                  :key="index"
                  style="width: 120px; height: 120px; object-fit: cover"
                  :src="item.content"
                ></video>
              </div>
            </van-uploader>
          </div>
        </li>
      </ul>
      <div class="warp-btn">
        <van-button type="info" color="rgb(38, 120, 255)" @click="handleSubmit"
          >立即提交</van-button
        >
      </div>
    </div>
    <van-dialog
      style="width: 100%; border-radius: 0; height: 200px"
      theme="default"
      v-model="showvideoplay"
      :show-cancel-button="false"
      :show-confirm-button="false"
      closeOnClickOverlay
    >
      <video
        controls
        preload="auto"
        style="width: 100%; height: 200px; object-fit: contain"
        :src="videourl"
        v-if="videourl"
        playsinline 
        x5-video-player-type="h5" 
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint" 
        x-webkit-airplay="true"
      ></video>
    </van-dialog>
  </div>
</template>

<script>
import httpService from "@/plugins/http";
import * as imageConversion from "image-conversion";
import { Toast } from "vant";
export default {
  data() {
    return {
      videourl: "",
      ruleforms: {},
      fileImgList: [],
      fileVideoList: [],
      showvideoplay: false,
    };
  },
  mounted() {},
  methods: {
    async modelImgRead(file) {
      if (file instanceof Array) {
        file.map((v) => {
          // v.status = "uploading";
          // v.message = "上传中...";
          this.fileUpload(v);
        });
      } else {
        // file.status = "uploading";
        // file.message = "上传中...";
        this.fileUpload(file);
      }
    },
    fileUpload(file) {
      let _file = file;

      _file.status = "uploading";
      _file.message = "上传中...";

      //创建表单
      var avatarData = new FormData();
      avatarData.append("file", file.file); //将图片加入上传表单

      var xhr = new XMLHttpRequest();
      xhr.open("post", "/api/sys/upload_file", true);
      xhr.upload.onprogress = progressFunction; //【上传进度调用方法实现】
      xhr.onload = uploadComplete; //请求完成
      xhr.onerror = uploadFailed; //请求失败
      xhr.onreadystatechange = function () {
        //当readyState变化时执行 （作为上传结果不可靠）
      };
      //上传进度
      function progressFunction(evt) {
        // event.total是需要传输的总字节，event.loaded是已经传输的字节。如果event.lengthComputable不为真，则event.total等于0
        if (evt.lengthComputable) {
          //vant框架这个没多大用处
          let complete = ((evt.loaded / evt.total) * 100) | 0;
          _file.upload_progress = complete;
        }
      }
      //上传成功响应
      function uploadComplete(evt) {
        //服务断接收完文件返回的结果
        if (evt.target.readyState == 4 && evt.target.status == 200) {
          var res = eval("(" + evt.target.responseText + ")");
          // file.url=res.result.url + "/" + res.result.imgs.file;
          _file.path = res.path; //用于表单提交保存到数据库
          _file.status = "done";
          _file.message = "上传成功";
        } else {
          _file.status = "failed";
          _file.message = "上传失败";
        }
        // Toast("上传成功！");
      }
      //上传失败
      function uploadFailed(evt) {
        _file.status = "failed";
        _file.message = "上传失败";
      }
      //开始上传
      xhr.send(avatarData);
    },
    handleImagebefread(file) {
      return new Promise((resolve, reject) => {
        console.log("压缩前", file);
        // Toast(file.size);
        // console.log('压缩前', file) // 压缩到400KB,大于400KB的图片都会进行压缩，小于则不会
        // 500是控制压缩大小的 根据你自己的需要调整 数值越小压缩越小
        imageConversion.compressAccurately(file, 1024).then((res) => {
          res = new File([res], file.name, {
            type: res.type,
            lastModified: Date.now(),
          });
          console.log("压缩后", res);
          resolve(res);
        });
      });
    },
    async uploadImg(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await httpService.post("/api/sys/upload_file", formData);
      if (res.code === 0) {
        if (file instanceof Array) {
          //判断是否是数组
          file.map((v, i) => {
            v.status = "success";
            v.message = "";
            v.path = res[i];
          });
        } else {
          file.status = "success";
          file.message = "";
          file.path = res.path;
        }
      }
    },
    handlebefread(e) {
      if (e.size > 104857600) {
        Toast("选择/录制视频不能超过100M");
        return false;
      }
      return true;
    },
    handlePreview(file) {
      this.videourl = file.content;
      this.showvideoplay = true;
    },
    async modelVideoRead(file) {
      if (file instanceof Array) {
        file.map((v) => {
          // v.status = "uploading";
          // v.message = "上传中...";
          this.fileUpload(v);
        });
      } else {
        // file.status = "uploading";
        // file.message = "上传中...";
        this.fileUpload(file);
      }
    },
    async uploadVideo(file) {
      const formData = new FormData();
      formData.append("file", file.file);
      const res = await httpService.post("/api/sys/upload_file", formData);
      if (res.code === 0) {
        if (file instanceof Array) {
          //判断是否是数组
          file.map((v, i) => {
            v.status = "success";
            v.message = "";
            v.path = res[i];
          });
        } else {
          file.status = "success";
          file.message = "";
          file.path = res.path;
        }
      }
    },
    // 提交
    async handleSubmit() {
      if (!this.ruleforms.hdl_info) {
        Toast("请输入处理说明");
        return;
      }
      try {
        this.ruleforms.hdl_pic = this.fileImgList
          .map((item) => {
            return item.path;
          })
          .join(",");
        this.ruleforms.hdl_video = this.fileVideoList
          .map((item) => {
            return item.path;
          })
          .join(",");
        this.$store.commit("showLoading");
        console.log(this.fileImgList, this.fileVideoList);
        const { code } = await httpService.post("/api/event/handle", {
          ...this.ruleforms,
          id: Number(this.$route.query.id),
          state:1
        });
        if (code === 0) {
          Toast("提交成功");
          this.$router.push({
            path: "dispatchTasks",
            query: {
              id: this.$route.query.id,
            },
          });
          // this.fileImgList = [];
          // this.fileVideoList = [];
          // this.ruleforms = {};
          // this.showvideoplay = false;
          // this.videourl = "";
        } else {
          this.$store.commit("hideLoading");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("hideLoading");
      }
    },
  },
};
</script>

<style lang="less">
.dispatchTasks-warp {
  letter-spacing: 0px;
  position: relative;
  overflow: auto;
}
.warp-btn {
  display: flex;
  padding: 20px;
  justify-content: center;
  .van-button {
    width: 112px;
  }
}
</style>
